// const header_top = document.querySelector('.header-top-content');
// const header_logo = document.querySelector('.banner-logo');

// var sticky = header_top.offsetTop;

// function addStickyNavbar() {
//   if (window.pageYOffset >= sticky) {
//     header_top.classList.add('sticky');
//   } else {
//     header_top.classList.remove('sticky');
//   }
// }


const initializeAll = () => {

  const form = document.getElementById('gfort-form_container-0');
  if(form !== null) {
    form.addEventListener('submit', event => {
      grecaptcha.ready( () => {
        grecaptcha.execute('6LdztOMkAAAAALZhdIDPMmU2UTbbl1SUoGQUis5G', {action: 'submit'}).then( (token) => {
          const captchaToken = document.getElementById('recaptcha-token');
          // const formContainer = document.getElementById('gfort-form_container-0');
          captchaToken.value = token; // add value to input hidden
          form.submit(); // submit form
        });
      });
    });
  }

  // window.onscroll = function() { 
  //   addStickyNavbar();
  // };



  if(window.cookieconsent) {
    
    window.cookieconsent.initialise({
      "palette": {
        "popup": {
        "background": "#161615",
        "color": "#fff"
        },
        "button": {
        "background": "var(--primary, black)",
        "color": "#fff"
        }
      },
      "theme": "classic",
      "content": {
        "message": cookiConsentStr,
        "dismiss": cookiCompris,
        "link": cookibtnName,
        "href": cookihref
      }
    })
  }
}

if (document.readyState == 'loading') {
  // still loading, wait for the event
  document.addEventListener('DOMContentLoaded', initializeAll);
} else {
  // DOM is ready!
  initializeAll();
}